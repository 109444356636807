import React, { useContext } from 'react';
import ReactTable from '../Table';
import { Link } from 'react-router-dom';
import { Parser } from 'html-to-react';
import { CTEMInsight1001Colors, CTEMInsight1002Colors, pentestFindingSeverityColors } from '../../utils/constants/asset';
import { LayoutContext } from '../../Providers/LayoutProvider';
import Typography from '../Typography';
import StackedBar from '../Widgets/StackedBarChart';
import BarChartComponent from '../Widgets/barChart';
import CounterChartWidget from '../Widgets/CounterChartWidget';

const KeyInsight = ({ description, data, insightId }) => {
    const htmlParser = new Parser();

    const { mainUrl } = useContext(LayoutContext);

    const processColumns = (metaData) => {
        return metaData.columns.map((col) => (
            {
            Header: col.title,
            accessor: col.accessor,
            className: col.className || '',
            Cell: ({ value, row }) => {
                // If a direct link is provided, use it
                if (col.link) {
                    const linkValue = row.original[col.linkValue]; 
                    const dynamicLink = col.link.replace('${' + col.linkValue + '}', linkValue); 
                    return (
                        <Link className='link' target='_blank' to={`${mainUrl}${dynamicLink}`}>
                            {value}
                        </Link>
                    );
                } 
    
                // Handle conditional links dynamically
                if (col.conditions) {
                    const rowData = row.original;
                    const matchedCondition = col.conditions.find(({ condition }) => {
                        try {
                            // Use Function constructor to evaluate conditions securely
                            return new Function('row', `return ${condition};`)(rowData);
                        } catch (error) {
                            console.error("Error evaluating condition:", error);
                            return false;
                        }
                    });
    
                    if (matchedCondition) {
                        let dynamicLink = matchedCondition.link;               
                        // Replace placeholders
                        dynamicLink = dynamicLink.replace(/\$\{row\.(\w+)\}/g, (_, key) => rowData[key] || '');
    
                        return (
                            <Link className='link' target='_blank' to={`${mainUrl}${dynamicLink}`}>
                                {value}
                            </Link>
                        );
                    }
                } 
    
                // Default case
                return <span className={col.className || ''}>{value}</span>;
            },
        }));
    };
    
    const graphData = data?.graphData?.data || [];
    const graphType = data?.graphData?.type;
    
    let graphComponent = null;
    
    if (graphType && graphData) {
        switch (graphType) {
            case 'bar':
                graphComponent = 
                <BarChartComponent  
                className={data?.graphData?.className}
                graphData={graphData} 
                colors={CTEMInsight1002Colors} 
                />;
                break;
            case 'stack':
                graphComponent = 
                <StackedBar 
                graphData={graphData} 
                colors={CTEMInsight1001Colors} 
                />;
                break;
            case 'donut':
                graphComponent = 
                <CounterChartWidget
                className={data?.graphData?.className}
                data={graphData || []} title={""}
                colors={pentestFindingSeverityColors} 
                />
                    break;
            default:
                graphComponent = <div>Unsupported graph type</div>;
                break;
        }
    }

    return (
        <div className='insight-container flex-[100%]'>
            <div className='upper-section'>
                <div className='description'>
                    <Typography variant='h7' className='text-headings'>Observation</Typography>
                    <div>{htmlParser.parse(data?.observation)}</div>
                    <Typography variant='h7' className='text-headings mt-5'>Recommended Action</Typography>
                    <div>{htmlParser.parse(data?.recommended_action)}</div>
                </div>
                <div className={`graph ${data?.graphData?.className}`}>
                {graphComponent}
                </div>
            </div>
            {
                data?.listing?.length > 0 && <div className='lower-section grid grid-cols-1 md:grid-cols-2 gap-5'>
                { data?.listing?.map((table, index) => {
                    const columns = processColumns(table.metaData);
                    const tableData = table.data;

                    return (
                        <div key={index} className='table-container'>
                            <div className='xl:col-span-12 heading mb-5'>
                                <Typography variant='h7' className='text-headings capitalize'>{table.tableName}</Typography>
                            </div>
                            <ReactTable
                                columns={columns}
                                data={tableData || []}
                                className='table lg:table-fixed middle-aligned'
                                headerClassName='background rounded sticky z-[5] top-0'
                                bodyClassName='text-headings'
                            />
                        </div>
                    );
                })}
            </div>
            }
            
        </div>
    );
};

export default KeyInsight;