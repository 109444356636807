import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeCTEMKeyInsightsModal } from '../../../../../redux/reducers/dashboard';
import Modal from '../../../../../components/MuiComponents/Modal';
import Typography from '../../../../../components/Typography';
import { useCTEMInsightDetails } from '../../../../../hooks/ctem';
import reactStringReplace from 'react-string-replace';
import Insight1 from './insightsContent/Insight1';
import Insight2 from './insightsContent/Insight2';
import Insight3 from './insightsContent/Insight3';
import Insight4 from './insightsContent/Insight4';
import Insight5 from './insightsContent/Insight5';
import MainLoader from '../../../../../components/Loader/MainLoader';
import { SIEMBA_CUSTOMER_ENGINEER } from '../../../../../utils/roles';
import KeyInsight from '../../../../../components/KeyInsight';

const CTEMKeyInsightModal = () => {
    const { ctemKeyInsightsModal: { insightId, title, description, companyId, count, detailedData = [], role } } = useSelector(state => state.dashboard);
    const shouldFetchData = !(role && role === SIEMBA_CUSTOMER_ENGINEER);
    const { data, isFetching } = useCTEMInsightDetails(companyId, insightId, shouldFetchData);
    const insight = !shouldFetchData ? detailedData.find((ele) => ele.insightId == insightId): (data && JSON.parse(data?.insight));

    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch(closeCTEMKeyInsightsModal())
    }

    return (
        <Modal
            open={true}
            maxWidth={"lg"}
            fullWidth={true}
            dialogTitle={<>
               <Typography variant={"h5"} className={`!text-white`}>
                    <span>{reactStringReplace(title, '{{COUNT}}', (match, i) => (
                            <span>{count}</span>
                        ))}</span>
                </Typography>
            </>}
            zeroPadding={true}
            onClose={closeModal}
        >
            <div className="text-left">
                {(isFetching) && <MainLoader/>}
                <div className="ctem-insight-container py-5">
                    <div className={"max-h-[500px] px-5 overflow-auto"}>
                        <div className="flex flex-[100%]">
                            {
                                <KeyInsight
                                    description={description}
                                    data={insight}
                                    insightId={insightId}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default CTEMKeyInsightModal;
