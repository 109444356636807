import React, {createContext, useContext, useEffect, useState} from "react";
import Storage from "../utils/Storage";
import {CHAT_WEB_SOCKET_URL} from "../utils/constants";
import {useQueryClient} from "react-query";
import {CONVERSATIONS, INBOX} from "../hooks/utils/constants";
import {useValidateLogin} from "../hooks/auth";
import {useMatch} from "react-router-dom";
import {LayoutContext} from "./LayoutProvider";

export const WebsocketContext = createContext()

const token = Storage.token
const url = CHAT_WEB_SOCKET_URL
let websocket;
try {
    websocket = new WebSocket(url, [token])
} catch (err) {
    console.log("web socket init error: ", err);
}

const WebsocketProvider = ({children}) => {
    const {mainUrl} = useContext(LayoutContext)
    const path = useMatch(`${mainUrl}/chat/:conversation_id`)
    const conversation_id = path?.params?.conversation_id
    const queryClient = useQueryClient()
    const {data} = useValidateLogin()
    const userId = data?.user?.id
    const [socket, setSocket] = useState(websocket)
    useEffect(() => {
            try {
                let ws = socket
                if (!ws) {
                    const token = Storage.token
                    const url = CHAT_WEB_SOCKET_URL
                    ws = new WebSocket(url, [token]);
                    setSocket(ws)
                    websocket = ws
                }
                ws.onopen = () => {
                    console.log("WebSocket connection established.");
                };
                ws.onmessage = (event) => {
                    const eventData = JSON.parse(event.data)
                    switch (eventData.type) {
                        case "new_message":
                            let message = eventData.data
                            message.authorUser = {
                                uuid: parseInt(message?.userId)
                            }
                            // set message data
                            queryClient.setQueryData([INBOX, message.conversation_id], (oldData) => {
                                if (!oldData) {
                                    oldData = {pages: [{messages: []}]}
                                }
                                let data = JSON.parse(JSON.stringify(oldData))
                                let messages = data.pages[0].messages
                                let userObj = data.pages[0].userObj
                                if (message[userId] !== undefined)
                                 message.authorUser = userObj[message?.userId]
                                let findMessage = messages?.find(el => el?.message_id === message?.message_id)
                                if (findMessage)
                                    findMessage.isSending = false
                                else
                                    messages.unshift(message)
                                return data
                            })
                            queryClient.invalidateQueries(INBOX)
                            //set conversation data
                            queryClient.setQueryData([CONVERSATIONS, {type: message?.is_group ? "channels" : "personal", search: ""}], (oldData) => {
                                let foundConversation = null
                                let newPagesArray = []
                                oldData?.pages.forEach(page => {
                                    // find conversation
                                    const foundIndex = page?.conversations?.findIndex(el => el?._id === message?.conversation_id)
                                    if (~foundIndex) {
                                        foundConversation = page?.conversations?.[foundIndex]
                                        page?.conversations?.splice(foundIndex, 1)
                                    }
                                    newPagesArray.push(page)
                                    })
                                if (foundConversation) {
                                    foundConversation.lastMessage = message?.text
                                    foundConversation.lastMessagedAt = message.timestamp
                                    if (message?.userId !== userId && message?.conversation_id !== conversation_id) {
                                        foundConversation.unReadCount = foundConversation.unReadCount ? foundConversation?.unReadCount + 1 : 1
                                    }
                                    newPagesArray?.[0]?.conversations?.unshift(foundConversation)
                                } else {
                                    queryClient.invalidateQueries(CONVERSATIONS)
                                }
                                    // let conversations = oldData?.pages?.[0]?.conversations
                                    // let data = conversations?.find(el => el._id === message?.conversation_id)
                                    // data.lastMessage = message?.text
                                    // data.lastMessagedAt = message.timestamp
                                    // if (message?.user_id != userId && message?.conversation_id !== conversation_id) {
                                    //     data.unReadCount = data.unReadCount ? data?.unReadCount + 1 : 1
                                    // }
                                    return ({
                                        pages: newPagesArray,
                                        pageParams: oldData?.pageParams
                                    })
                                    
                                }
                                
                            )
                            queryClient.invalidateQueries(CONVERSATIONS)
                            break;
                    }
                }
                ws.onclose = () => {
                    console.log("close")
                    setSocket(null)
                }
            } catch
                (e) {
                console.log(e)
            }
        }
        ,
        [socket, setSocket, conversation_id]
    )
    return (
        <WebsocketContext.Provider value={socket}>
            {children}
        </WebsocketContext.Provider>
    );
};

export default WebsocketProvider;
