import HomeIcon from "@mui/icons-material/Home";
import MyCommunityIcon from "../../../SvgIcons/MyCommunityIcon";
import PersonIcon from "../../../SvgIcons/PersonIcon";
import MyTeamIcon from "../../../SvgIcons/MyTeamIcon";
import SearchIcon from "@mui/icons-material/Search";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ShieldIcon from "@mui/icons-material/Shield";
import AllBlockersIcon from "../../../SvgIcons/AllBlockersIcon";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import GPTIconWhite from "../../../SvgIcons/GPTIconWhite";
import PolicyIcon from '@mui/icons-material/Policy';
import VAIcon from "../../../SvgIcons/VALogo";
import EASMIcon from "../../../SvgIcons/EASMIcon";

const menu = [
    {
        path: "/admin/dashboard",
        icon: HomeIcon,
        label: "My Dashboard"
    },
    // {
    //     path: "/admin/community",
    //     icon: MyCommunityIcon,
    //     label: "My Community"
    // },
    {
        path: "/admin/customers",
        icon: PersonIcon,
        label: "My Customers"
    },
    {
        path: "/admin/team",
        icon: MyTeamIcon,
        label: "My Team"
    },
    {
        path: "/admin/all_assets",
        icon: WysiwygIcon,
        label: "Assets"
    },
    {
        icon: EASMIcon,
        label: "EASM",
        child: [
            {
                path: "/admin/easm/insights",
                label: "Overview"
            },
            {
                path: "/admin/easm/monitoring",
                label: "Monitoring"
            },
            {
                path: "/admin/easm/attack_surface",
                label: "Attack Surface"
            }
        ],
    },
    {
        icon: GPTIconWhite,
        label: "GenPT",
        child: [
            {
                path: "/admin/genpt/tests",
                label: "All Tests"
            },
            {
                path: "/admin/genpt/all_findings",
                label: "All Findings"
            },
        ]
    },
    {
        icon: VAIcon,
        label: "Vul. Assessments",
        child: [
            {
                path: "/admin/all_va",
                label: "All Scans"
            },
            {
                path: "/admin/va/all_findings",
                label: "All Findings"
            }
        ],
    },
    {
        icon: ShieldIcon,
        label: "Pentests",
        child: [
            {
                path: "/admin/pentests/insights",
                label: "Overview"
            },
            {
                path: "/admin/pentests/all_findings",
                label: "All Findings"
            },
            {
                path: "/admin/all_pentests",
                label: "All Pentests"
            },
            {
                path: "/admin/pentests/inflight",
                label: "Inflight"
            },
            {
                path: "/admin/pentests/closed",
                label: "Closed"
            },
            {
                path: "/admin/pentests/upcoming",
                label: "Upcoming"
            },
            {
                path: "/admin/pentests/draft",
                label: "Drafts"
            },
            {
                path: "/admin/pentests/all_blockers",
                label: "All Blockers"
            },
        ]
    },
    {
        path: "/admin/chat",
        icon: ChatBubbleOutlineIcon,
        label: "Chat"
    },
    {
        path: "/admin/asset_management",
        icon: SettingsBrightnessIcon,
        label: "Asset Management",
    },
    {
        path: "/admin/user_management",
        icon: PersonIcon,
        label: "User Management"
    },
    {
        path: "/admin/company-details",
        icon: PersonIcon,
        label: "Company Details"
    }
]
export default menu;
