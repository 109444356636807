

import React, {memo} from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import styles from './styles.module.scss'

const WarningIcon = props => {
    return (
        <ReportProblemIcon className={styles.warning}/>
    );
};

export default memo(WarningIcon);
