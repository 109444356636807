import React, { createContext, useState } from 'react';
import Modal from "../MuiComponents/Modal";
import SuccessIcon from "./SuccessIcon";
import Typography from "../Typography";
import Button from "../Button";
import ErrorIcon from "./ErrorIcon";
import { MuiTextField } from '../MuiComponents/FormInputs';
import { address, dataSpaceValidation, domain, email, nameValidation, required_message, website_url } from "../../utils/validations";
import WarningIcon from './WarningIcon';

export const AlertContext = createContext()
const initialState = {
    open: false,
    title: null,
    variant: null,
    description: null,
    hideCloseIcon: false,
    onSubmit: () => {
    },
    onClose: () => {
    },
    okayText: null,
    cancelText: null
}

const AlertProvider = ({ children }) => {
    const [state, setState] = useState(initialState)
    const { variant, cancelText, okayText, hideCloseIcon, formOptions } = state;
    const [justificationData, setJustificationData] = useState('')
    const [disabledJustification, setDisabledJustification] = useState(true);
    const [errorMessage, updateErrorMessage] = useState(null);
    
    const validateJustification = (justificationData) => {
        return !dataSpaceValidation.test(justificationData) && justificationData.length === 0;
    }

    const [fieldValue, updateFieldValue] = useState("");
    const popup = (options) => {
        return new Promise((resolve, reject) => {
            setState({
                ...state,
                ...options,
                open: true,
                onSubmit: resolve,
            })
        })
    }
    const shouldHideOkayButton = state.hideOkayButton || false; // Default value is false if the prop is not provided

    const onClose = () => {
        initialState.hideOkayButton = shouldHideOkayButton
        setJustificationData("")
        updateErrorMessage("");
        setState(initialState)
    }
    const onOkay = () => {
        if (state.justification) {
            state.setValue("justification", fieldValue);
        }
        state.onSubmit()
        setJustificationData("")
        setState(initialState)
    }
    const disabled = justificationData.length === 0
    const dataSpaceValidation = /^(?:\S.*\S|\S?)$/gm;
    return (
        <AlertContext.Provider value={[popup, onClose]}>
            {children}
            <Modal open={state.open}
                style={{ zIndex: 9999 }}
                {...{
                    ...(!hideCloseIcon ? { onClose } : {})
                }}
                PaperProps={{ className: variant === 'justification' ? '!min-w-[423px]' : variant == 'alt_names' ? '!min-w-[523px]' :  `!max-w-[423px]` }}>
                {variant === "success" && <SuccessIcon />}
                {["warning", "error"].includes(variant) && <ErrorIcon />}
                {["warning!"].includes(variant) && <WarningIcon />}
                <Typography variant={"h4"} className={"text-primary-800 pb-2.5 px-2.5"}>{state.title}</Typography>
                <Typography variant={"body1"}>{state.description}</Typography>
                {variant === "justification" ? (
                   <>
                   <form onSubmit={formOptions.handleSubmit(onOkay)}>
                        <MuiTextField
                            label={"Enter justification here..."}
                            multiline
                            rows={3}
                            value = {justificationData}
                            InputProps={{
                                ...formOptions.register("justification", { required: required_message, ...dataSpaceValidation }),
                                onChange: (e) => {
                                const val = e.target.value;
                                const dataValidation = e.target.value.length === 0;
                                const spaceValidation = !dataSpaceValidation.test(e.target.value);
                                if(dataValidation) {
                                    updateErrorMessage("Justification is required");
                                    setDisabledJustification(true);
                                } else if (spaceValidation) {
                                    updateErrorMessage("Entered value cant start/end or contain only white spacing");
                                    setDisabledJustification(true);
                                } else {
                                    updateErrorMessage("");
                                    setDisabledJustification(false);
                                }
                                setJustificationData(val); // updating the default justification
                            }
                            }}
                            inputProps = {{
                                maxLength: 2000
                            }}
                            error={errorMessage}
                        />
                        {/* TODO: Handle this with form props - the error object is not getting initialized */}
                        <p className='justification-error'>{errorMessage}</p>
                        <div className={"text-center mt-6"}>
                        {cancelText && <Button variant={"outlined"} size={"small"} onClick={onClose}
                                            className={"mt-2.5 mr-2.5"}>{cancelText || "Cancel"}</Button>}
                        <Button disabled={disabledJustification} className={"mt-2.5"}>{okayText || "Okay"}</Button>
                        </div>
                    </form>
                    </>
                ) : (
                    <div className={"text-center mt-6"}>
                        {cancelText && <Button variant={"outlined"} size={"small"} onClick={onClose}
                            className={"mt-2.5 mr-2.5"}>{cancelText || "Cancel"}</Button>}
                        {!shouldHideOkayButton && (
                                <Button onClick={onOkay} className={"mt-2.5"}>
                                    {okayText || "Okay"}
                                </Button>
                        )}
                    </div>
                )
                }
            </Modal>
        </AlertContext.Provider>
    );
};

export default AlertProvider;

